module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://rajrathore.herokuapp.com","contentApiKey":"4e25c8258b9034a141ba55fcd2"},"production":{"apiUrl":"https://rajrathore.herokuapp.com","contentApiKey":"4e25c8258b9034a141ba55fcd2"}},"siteConfig":{"siteUrl":"https://www.rajrathore.com","postsPerPage":3,"siteTitleMeta":"Raj Rathore's Blog","siteDescriptionMeta":"A sneak peek into my life.... a programmer's thoughts, stories and ideas.","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"Raj's Blog","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","gatsbyImages":false},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50333833-1"},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":2},
    }]
